"use client";

import { useEffect, useState } from "react";
import Logo from "@/icons/Logo";
import Phone from "@/icons/Phone";
import { Button, Link } from "@nextui-org/react";
import { usePathname } from "next/navigation";

export default function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const currentPath = usePathname();

  useEffect(() => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    }

    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`font-inter fixed z-50 flex w-full select-none justify-center border-neutral-900 transition-colors ${currentPath.includes("/privacy-policy") ? "border-b-1 bg-neutral-950" : isScrolled ? "border-b-1 bg-neutral-950" : "border-b-0 bg-gradient-to-b from-neutral-950/80 to-neutral-950/0"}`}
    >
      <div className="flex h-16 w-full max-w-7xl items-center justify-between px-6">
        <Link href="/" aria-label="Strona Główna">
          <Logo className="h-6 w-auto text-neutral-50 sm:h-7" />
        </Link>

        <div className="flex w-full items-center justify-end gap-9">
          <div className={`hidden gap-6 md:flex ${currentPath.includes("/privacy-policy") ? "md:hidden" : ""}`}>
            <Link href="#marki" className="text-sm text-neutral-50 drop-shadow-lg">
              Marki
            </Link>
            <Link href="#uslugi" className="text-sm text-neutral-50 drop-shadow-2xl">
              Usługi
            </Link>
            <Link href="#o-nas" className="text-sm text-neutral-50 drop-shadow-2xl">
              O nas
            </Link>
            <Link href="#kariera" className="text-sm text-neutral-50 drop-shadow-2xl">
              Kariera
            </Link>
            <Link href="#kontakt" className="text-sm text-neutral-50 drop-shadow-2xl">
              Kontakt
            </Link>
          </div>
          {currentPath.includes("/privacy-policy") && (
            <Link href="/" className="text-sm text-neutral-50">
              Strona Główna
            </Link>
          )}
          {!currentPath.includes("/privacy-policy") && (
            <Button className="flex max-h-11 gap-2 rounded-none bg-red-600 text-xs font-semibold text-neutral-50" as={Link} href="tel:+48501587483">
              <Phone className="h-5 w-5" /> Zadzwoń teraz
            </Button>
          )}
        </div>
      </div>
    </nav>
  );
}
