import React, { forwardRef } from "react";
import { cn } from "../utils/classNames";

const Logo = forwardRef(({ className, ...props }, ref) => (
  <svg ref={ref} {...props} className={cn("", className)} width={168} height={35} viewBox="0 0 168 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.08416 15.9374H0L5.3048 0.214956H10.3639L15.6687 15.9374H11.5846L7.89195 4.17629H7.76912L4.08416 15.9374ZM3.5391 9.74979H12.0759V12.6363H3.5391V9.74979Z"
      fill="#EC1A26"
    />
    <path
      d="M26.441 0.214956H30.2334V10.3563C30.2334 11.5283 29.9545 12.5493 29.3966 13.4194C28.8387 14.2843 28.0608 14.9548 27.0628 15.4308C26.0648 15.9016 24.9056 16.137 23.5851 16.137C22.2493 16.137 21.0824 15.9016 20.0844 15.4308C19.0864 14.9548 18.311 14.2843 17.7583 13.4194C17.2055 12.5493 16.9292 11.5283 16.9292 10.3563V0.214956H20.7293V10.0262C20.7293 10.5687 20.847 11.0523 21.0824 11.4771C21.323 11.9019 21.6582 12.2346 22.0881 12.4751C22.518 12.7157 23.017 12.8359 23.5851 12.8359C24.1532 12.8359 24.6497 12.7157 25.0745 12.4751C25.5044 12.2346 25.8396 11.9019 26.0801 11.4771C26.3207 11.0523 26.441 10.5687 26.441 10.0262V0.214956Z"
      fill="#EC1A26"
    />
    <path d="M31.6685 3.30111V0.214956H44.9573V3.30111H40.1899V15.9374H36.4435V3.30111H31.6685Z" fill="#EC1A26" />
    <path
      d="M60.5027 8.0762C60.5027 9.80608 60.17 11.2724 59.5047 12.4751C58.8394 13.6778 57.9386 14.5914 56.8024 15.2158C55.6713 15.8402 54.4021 16.1524 52.9946 16.1524C51.582 16.1524 50.3102 15.8376 49.1791 15.2081C48.0481 14.5786 47.1499 13.6651 46.4845 12.4674C45.8243 11.2647 45.4942 9.80097 45.4942 8.0762C45.4942 6.34632 45.8243 4.88001 46.4845 3.67728C47.1499 2.47455 48.0481 1.56099 49.1791 0.936594C50.3102 0.312198 51.582 0 52.9946 0C54.4021 0 55.6713 0.312198 56.8024 0.936594C57.9386 1.56099 58.8394 2.47455 59.5047 3.67728C60.17 4.88001 60.5027 6.34632 60.5027 8.0762ZM56.6182 8.0762C56.6182 7.0526 56.4723 6.18766 56.1806 5.48138C55.894 4.77509 55.4794 4.24026 54.9369 3.87688C54.3995 3.51351 53.7521 3.33182 52.9946 3.33182C52.2423 3.33182 51.5948 3.51351 51.0523 3.87688C50.5098 4.24026 50.0927 4.77509 49.801 5.48138C49.5144 6.18766 49.3711 7.0526 49.3711 8.0762C49.3711 9.0998 49.5144 9.96474 49.801 10.671C50.0927 11.3773 50.5098 11.9121 51.0523 12.2755C51.5948 12.6389 52.2423 12.8206 52.9946 12.8206C53.7521 12.8206 54.3995 12.6389 54.9369 12.2755C55.4794 11.9121 55.894 11.3773 56.1806 10.671C56.4723 9.96474 56.6182 9.0998 56.6182 8.0762Z"
      fill="#EC1A26"
    />
    <path
      d="M76.5605 5.91129H72.722C72.6709 5.5172 72.5659 5.1615 72.4073 4.84418C72.2486 4.52687 72.0388 4.25562 71.7778 4.03042C71.5168 3.80523 71.2071 3.63378 70.8489 3.51606C70.4957 3.39323 70.1042 3.33182 69.6743 3.33182C68.9117 3.33182 68.254 3.51862 67.7013 3.89224C67.1537 4.26585 66.7314 4.8058 66.4346 5.51208C66.1429 6.21837 65.997 7.07307 65.997 8.0762C65.997 9.12027 66.1454 9.99545 66.4423 10.7017C66.7442 11.4029 67.1665 11.9326 67.709 12.2909C68.2566 12.644 68.904 12.8206 69.6512 12.8206C70.0709 12.8206 70.4522 12.7668 70.7951 12.6594C71.1431 12.5519 71.4477 12.3958 71.7087 12.1911C71.9748 11.9812 72.1923 11.7279 72.3612 11.431C72.5352 11.1291 72.6555 10.7887 72.722 10.41L76.5605 10.433C76.494 11.1291 76.2918 11.8149 75.9541 12.4905C75.6214 13.166 75.1633 13.7828 74.5799 14.3406C73.9964 14.8934 73.285 15.3335 72.4457 15.6611C71.6114 15.9886 70.6544 16.1524 69.5745 16.1524C68.1517 16.1524 66.8773 15.8402 65.7513 15.2158C64.6305 14.5863 63.7451 13.6702 63.0951 12.4674C62.4451 11.2647 62.1201 9.80097 62.1201 8.0762C62.1201 6.34632 62.4502 4.88001 63.1104 3.67728C63.7707 2.47455 64.6638 1.56099 65.7897 0.936594C66.9157 0.312198 68.1773 0 69.5745 0C70.5264 0 71.4067 0.133068 72.2154 0.399204C73.024 0.660222 73.7354 1.04407 74.3496 1.55075C74.9637 2.05232 75.4627 2.66904 75.8466 3.40091C76.2304 4.13278 76.4684 4.96958 76.5605 5.91129Z"
      fill="currentColor"
    />
    <path d="M78.3027 15.9374V0.214956H89.2654V3.30111H82.1028V6.52545H88.705V9.61928H82.1028V12.8513H89.2654V15.9374H78.3027Z" fill="currentColor" />
    <path d="M104.594 0.214956V15.9374H101.37L95.1128 6.86323H95.013V15.9374H91.2129V0.214956H94.4833L100.671 9.27381H100.801V0.214956H104.594Z" fill="currentColor" />
    <path d="M106.037 3.30111V0.214956H119.326V3.30111H114.558V15.9374H110.812V3.30111H106.037Z" fill="currentColor" />
    <path
      d="M120.749 15.9374V0.214956H127.244C128.421 0.214956 129.437 0.427353 130.292 0.852147C131.151 1.27182 131.814 1.87575 132.28 2.66392C132.746 3.44697 132.979 4.37589 132.979 5.45067C132.979 6.5408 132.741 7.46716 132.265 8.22974C131.789 8.9872 131.113 9.56554 130.238 9.96474C129.363 10.3588 128.326 10.5559 127.129 10.5559H123.021V7.56184H126.422C126.996 7.56184 127.474 7.48763 127.858 7.33921C128.247 7.18567 128.541 6.95536 128.741 6.64828C128.94 6.33608 129.04 5.93688 129.04 5.45067C129.04 4.96446 128.94 4.5627 128.741 4.24538C128.541 3.92295 128.247 3.6824 127.858 3.52374C127.469 3.35997 126.99 3.27808 126.422 3.27808H124.549V15.9374H120.749ZM129.601 8.75178L133.516 15.9374H129.37L125.532 8.75178H129.601Z"
      fill="currentColor"
    />
    <path
      d="M144.142 0.214956H147.935V10.3563C147.935 11.5283 147.656 12.5493 147.098 13.4194C146.54 14.2843 145.762 14.9548 144.764 15.4308C143.766 15.9016 142.607 16.137 141.286 16.137C139.951 16.137 138.784 15.9016 137.786 15.4308C136.788 14.9548 136.012 14.2843 135.46 13.4194C134.907 12.5493 134.63 11.5283 134.63 10.3563V0.214956H138.431V10.0262C138.431 10.5687 138.548 11.0523 138.784 11.4771C139.024 11.9019 139.36 12.2346 139.789 12.4751C140.219 12.7157 140.718 12.8359 141.286 12.8359C141.855 12.8359 142.351 12.7157 142.776 12.4751C143.206 12.2346 143.541 11.9019 143.781 11.4771C144.022 11.0523 144.142 10.5687 144.142 10.0262V0.214956Z"
      fill="currentColor"
    />
    <path
      d="M149.884 0.214956H154.59L158.582 9.94939H158.766L162.758 0.214956H167.464V15.9374H163.764V6.27978H163.634L159.857 15.8376H157.492L153.715 6.22605H153.584V15.9374H149.884V0.214956Z"
      fill="currentColor"
    />
    <path
      d="M35.9601 22.5704H38.2837L41.3954 30.1655H41.5183L44.63 22.5704H46.9536V33.052H45.1316V25.851H45.0343L42.1375 33.0213H40.7761L37.8794 25.8357H37.7821V33.052H35.9601V22.5704Z"
      fill="currentColor"
    />
    <path
      d="M50.9133 33.2107C50.4151 33.2107 49.9664 33.122 49.5672 32.9446C49.1714 32.7637 48.8575 32.4976 48.6255 32.1461C48.3969 31.7947 48.2826 31.3614 48.2826 30.8462C48.2826 30.4026 48.3645 30.0358 48.5283 29.7458C48.6921 29.4558 48.9155 29.2238 49.1987 29.0498C49.4819 28.8757 49.8009 28.7444 50.1558 28.6557C50.5141 28.5635 50.8843 28.497 51.2664 28.4561C51.727 28.4083 52.1006 28.3657 52.3872 28.3281C52.6739 28.2872 52.882 28.2258 53.0116 28.1439C53.1447 28.0586 53.2112 27.9272 53.2112 27.7498V27.7191C53.2112 27.3335 53.0969 27.035 52.8683 26.8234C52.6397 26.6119 52.3105 26.5061 51.8806 26.5061C51.4268 26.5061 51.0668 26.6051 50.8007 26.803C50.5379 27.0009 50.3605 27.2346 50.2684 27.5041L48.5385 27.2585C48.675 26.7808 48.9002 26.3816 49.2141 26.0608C49.528 25.7367 49.9118 25.4945 50.3656 25.3341C50.8194 25.1703 51.321 25.0884 51.8703 25.0884C52.2491 25.0884 52.6261 25.1328 53.0014 25.2215C53.3767 25.3102 53.7196 25.4569 54.0301 25.6616C54.3406 25.8629 54.5897 26.1376 54.7774 26.4856C54.9684 26.8337 55.064 27.2687 55.064 27.7907V33.052H53.2829V31.9721H53.2215C53.1089 32.1905 52.9502 32.3952 52.7455 32.5863C52.5442 32.774 52.29 32.9258 51.9829 33.0418C51.6793 33.1544 51.3227 33.2107 50.9133 33.2107ZM51.3944 31.8493C51.7663 31.8493 52.0887 31.7759 52.3617 31.6292C52.6346 31.4791 52.8445 31.2812 52.9912 31.0355C53.1413 30.7899 53.2164 30.522 53.2164 30.232V29.3057C53.1584 29.3534 53.0594 29.3978 52.9195 29.4387C52.783 29.4797 52.6295 29.5155 52.4589 29.5462C52.2883 29.5769 52.1194 29.6042 51.9522 29.6281C51.785 29.652 51.64 29.6724 51.5172 29.6895C51.2408 29.727 50.9934 29.7885 50.7751 29.8738C50.5567 29.9591 50.3844 30.0785 50.2582 30.232C50.1319 30.3821 50.0688 30.5766 50.0688 30.8155C50.0688 31.1567 50.1933 31.4143 50.4424 31.5883C50.6915 31.7623 51.0088 31.8493 51.3944 31.8493Z"
      fill="currentColor"
    />
    <path
      d="M56.6387 33.052V25.1908H58.4351V26.501H58.517C58.6603 26.0472 58.9059 25.6975 59.254 25.4518C59.6054 25.2027 60.0063 25.0782 60.4567 25.0782C60.5591 25.0782 60.6734 25.0833 60.7996 25.0935C60.9293 25.1004 61.0367 25.1123 61.122 25.1294V26.8337C61.0435 26.8064 60.919 26.7825 60.7484 26.762C60.5812 26.7381 60.4192 26.7262 60.2622 26.7262C59.9244 26.7262 59.6208 26.7995 59.3512 26.9463C59.0851 27.0896 58.8752 27.2892 58.7217 27.5451C58.5681 27.801 58.4914 28.0961 58.4914 28.4305V33.052H56.6387Z"
      fill="currentColor"
    />
    <path
      d="M65.17 33.2056C64.3852 33.2056 63.7114 33.0333 63.1484 32.6887C62.5888 32.344 62.1572 31.8681 61.8535 31.2607C61.5533 30.65 61.4032 29.9471 61.4032 29.1521C61.4032 28.3537 61.5567 27.6491 61.8638 27.0384C62.1709 26.4242 62.6042 25.9465 63.1637 25.6053C63.7267 25.2607 64.3921 25.0884 65.1598 25.0884C65.7978 25.0884 66.3625 25.2061 66.8538 25.4416C67.3486 25.6736 67.7427 26.0028 68.0361 26.4293C68.3295 26.8524 68.4967 27.3472 68.5377 27.9136H66.7668C66.6952 27.5348 66.5246 27.2192 66.255 26.9667C65.9889 26.7108 65.6323 26.5829 65.1854 26.5829C64.8066 26.5829 64.474 26.6852 64.1874 26.89C63.9007 27.0913 63.6773 27.3813 63.5169 27.76C63.3599 28.1388 63.2815 28.5925 63.2815 29.1214C63.2815 29.6571 63.3599 30.1177 63.5169 30.5033C63.6738 30.8854 63.8939 31.1805 64.1771 31.3887C64.4637 31.5934 64.7998 31.6958 65.1854 31.6958C65.4583 31.6958 65.7023 31.6446 65.9172 31.5422C66.1356 31.4365 66.3181 31.2846 66.4649 31.0867C66.6116 30.8888 66.7122 30.6483 66.7668 30.3651H68.5377C68.4933 30.9212 68.3295 31.4143 68.0463 31.8442C67.7631 32.2707 67.3776 32.6051 66.8897 32.8473C66.4017 33.0862 65.8285 33.2056 65.17 33.2056Z"
      fill="currentColor"
    />
    <path
      d="M69.7707 33.052V25.1908H71.6234V33.052H69.7707ZM70.7022 24.0751C70.4088 24.0751 70.1563 23.9778 69.9447 23.7833C69.7332 23.5854 69.6274 23.3483 69.6274 23.0719C69.6274 22.7922 69.7332 22.555 69.9447 22.3605C70.1563 22.1626 70.4088 22.0637 70.7022 22.0637C70.999 22.0637 71.2515 22.1626 71.4597 22.3605C71.6712 22.555 71.777 22.7922 71.777 23.0719C71.777 23.3483 71.6712 23.5854 71.4597 23.7833C71.2515 23.9778 70.999 24.0751 70.7022 24.0751Z"
      fill="currentColor"
    />
    <path
      d="M75.0931 28.4458V33.052H73.2404V25.1908H75.0112V26.5266H75.1033C75.2842 26.0864 75.5725 25.7367 75.9683 25.4774C76.3675 25.2181 76.8605 25.0884 77.4474 25.0884C77.9899 25.0884 78.4624 25.2044 78.8651 25.4364C79.2711 25.6685 79.585 26.0045 79.8068 26.4447C80.032 26.8848 80.1428 27.4188 80.1394 28.0466V33.052H78.2867V28.3332C78.2867 27.8078 78.1502 27.3966 77.8773 27.0998C77.6077 26.803 77.2341 26.6545 76.7564 26.6545C76.4323 26.6545 76.144 26.7262 75.8915 26.8695C75.6424 27.0094 75.4462 27.2124 75.3029 27.4785C75.163 27.7447 75.0931 28.0671 75.0931 28.4458Z"
      fill="currentColor"
    />
    <path d="M93.7168 22.5704V33.052H92.0278L87.089 25.9124H87.0019V33.052H85.1032V22.5704H86.8023L91.7361 29.7151H91.8282V22.5704H93.7168Z" fill="currentColor" />
    <path
      d="M97.6777 33.2107C97.1796 33.2107 96.7309 33.122 96.3317 32.9446C95.9359 32.7637 95.622 32.4976 95.39 32.1461C95.1614 31.7947 95.0471 31.3614 95.0471 30.8462C95.0471 30.4026 95.129 30.0358 95.2927 29.7458C95.4565 29.4558 95.68 29.2238 95.9632 29.0498C96.2464 28.8757 96.5654 28.7444 96.9203 28.6557C97.2785 28.5635 97.6487 28.497 98.0309 28.4561C98.4915 28.4083 98.8651 28.3657 99.1517 28.3281C99.4383 28.2872 99.6465 28.2258 99.7761 28.1439C99.9092 28.0586 99.9757 27.9272 99.9757 27.7498V27.7191C99.9757 27.3335 99.8614 27.035 99.6328 26.8234C99.4042 26.6119 99.075 26.5061 98.645 26.5061C98.1912 26.5061 97.8313 26.6051 97.5651 26.803C97.3024 27.0009 97.125 27.2346 97.0329 27.5041L95.303 27.2585C95.4395 26.7808 95.6647 26.3816 95.9786 26.0608C96.2925 25.7367 96.6763 25.4945 97.1301 25.3341C97.5839 25.1703 98.0855 25.0884 98.6348 25.0884C99.0135 25.0884 99.3906 25.1328 99.7659 25.2215C100.141 25.3102 100.484 25.4569 100.795 25.6616C101.105 25.8629 101.354 26.1376 101.542 26.4856C101.733 26.8337 101.828 27.2687 101.828 27.7907V33.052H100.047V31.9721H99.986C99.8734 32.1905 99.7147 32.3952 99.51 32.5863C99.3087 32.774 99.0545 32.9258 98.7474 33.0418C98.4437 33.1544 98.0872 33.2107 97.6777 33.2107ZM98.1588 31.8493C98.5307 31.8493 98.8532 31.7759 99.1261 31.6292C99.3991 31.4791 99.6089 31.2812 99.7556 31.0355C99.9058 30.7899 99.9808 30.522 99.9808 30.232V29.3057C99.9228 29.3534 99.8239 29.3978 99.684 29.4387C99.5475 29.4797 99.394 29.5155 99.2234 29.5462C99.0528 29.5769 98.8839 29.6042 98.7167 29.6281C98.5495 29.652 98.4045 29.6724 98.2817 29.6895C98.0053 29.727 97.7579 29.7885 97.5396 29.8738C97.3212 29.9591 97.1489 30.0785 97.0226 30.232C96.8964 30.3821 96.8333 30.5766 96.8333 30.8155C96.8333 31.1567 96.9578 31.4143 97.2069 31.5883C97.456 31.7623 97.7733 31.8493 98.1588 31.8493Z"
      fill="currentColor"
    />
    <path
      d="M103.403 36V25.1908H105.225V26.4908H105.333C105.428 26.2997 105.563 26.0967 105.737 25.8817C105.911 25.6633 106.146 25.4774 106.443 25.3239C106.74 25.1669 107.119 25.0884 107.579 25.0884C108.187 25.0884 108.734 25.2437 109.222 25.5542C109.714 25.8612 110.103 26.3167 110.389 26.9207C110.679 27.5212 110.824 28.2582 110.824 29.1316C110.824 29.9949 110.683 30.7285 110.399 31.3324C110.116 31.9363 109.731 32.3969 109.243 32.7142C108.755 33.0316 108.202 33.1902 107.585 33.1902C107.134 33.1902 106.761 33.1152 106.464 32.965C106.167 32.8149 105.928 32.6341 105.747 32.4225C105.57 32.2076 105.432 32.0045 105.333 31.8135H105.256V36H103.403ZM105.22 29.1214C105.22 29.6298 105.292 30.0751 105.435 30.4572C105.582 30.8393 105.792 31.1379 106.064 31.3529C106.341 31.5644 106.675 31.6702 107.068 31.6702C107.477 31.6702 107.82 31.561 108.096 31.3426C108.373 31.1208 108.581 30.8189 108.721 30.4367C108.864 30.0512 108.936 29.6127 108.936 29.1214C108.936 28.6335 108.866 28.2002 108.726 27.8214C108.586 27.4427 108.378 27.1459 108.101 26.9309C107.825 26.716 107.48 26.6085 107.068 26.6085C106.672 26.6085 106.336 26.7125 106.059 26.9207C105.783 27.1288 105.573 27.4205 105.43 27.7958C105.29 28.1712 105.22 28.613 105.22 29.1214Z"
      fill="currentColor"
    />
    <path
      d="M115.552 33.2056C114.784 33.2056 114.119 33.0367 113.556 32.6989C112.993 32.3611 112.556 31.8885 112.245 31.2812C111.938 30.6739 111.785 29.9642 111.785 29.1521C111.785 28.3401 111.938 27.6287 112.245 27.0179C112.556 26.4072 112.993 25.9329 113.556 25.5951C114.119 25.2573 114.784 25.0884 115.552 25.0884C116.319 25.0884 116.985 25.2573 117.548 25.5951C118.111 25.9329 118.546 26.4072 118.853 27.0179C119.163 27.6287 119.318 28.3401 119.318 29.1521C119.318 29.9642 119.163 30.6739 118.853 31.2812C118.546 31.8885 118.111 32.3611 117.548 32.6989C116.985 33.0367 116.319 33.2056 115.552 33.2056ZM115.562 31.7213C115.978 31.7213 116.326 31.607 116.606 31.3784C116.886 31.1464 117.094 30.8359 117.23 30.447C117.37 30.058 117.44 29.6247 117.44 29.147C117.44 28.6659 117.37 28.2309 117.23 27.8419C117.094 27.4495 116.886 27.1373 116.606 26.9053C116.326 26.6733 115.978 26.5573 115.562 26.5573C115.135 26.5573 114.781 26.6733 114.497 26.9053C114.218 27.1373 114.008 27.4495 113.868 27.8419C113.731 28.2309 113.663 28.6659 113.663 29.147C113.663 29.6247 113.731 30.058 113.868 30.447C114.008 30.8359 114.218 31.1464 114.497 31.3784C114.781 31.607 115.135 31.7213 115.562 31.7213Z"
      fill="currentColor"
    />
    <path
      d="M120.603 33.052V25.1908H122.399V26.501H122.481C122.624 26.0472 122.87 25.6975 123.218 25.4518C123.569 25.2027 123.97 25.0782 124.421 25.0782C124.523 25.0782 124.637 25.0833 124.764 25.0935C124.893 25.1004 125.001 25.1123 125.086 25.1294V26.8337C125.008 26.8064 124.883 26.7825 124.712 26.762C124.545 26.7381 124.383 26.7262 124.226 26.7262C123.888 26.7262 123.585 26.7995 123.315 26.9463C123.049 27.0896 122.839 27.2892 122.686 27.5451C122.532 27.801 122.455 28.0961 122.455 28.4305V33.052H120.603Z"
      fill="currentColor"
    />
    <path
      d="M128.15 33.2107C127.652 33.2107 127.203 33.122 126.804 32.9446C126.408 32.7637 126.094 32.4976 125.862 32.1461C125.634 31.7947 125.519 31.3614 125.519 30.8462C125.519 30.4026 125.601 30.0358 125.765 29.7458C125.929 29.4558 126.152 29.2238 126.436 29.0498C126.719 28.8757 127.038 28.7444 127.393 28.6557C127.751 28.5635 128.121 28.497 128.503 28.4561C128.964 28.4083 129.338 28.3657 129.624 28.3281C129.911 28.2872 130.119 28.2258 130.249 28.1439C130.382 28.0586 130.448 27.9272 130.448 27.7498V27.7191C130.448 27.3335 130.334 27.035 130.105 26.8234C129.877 26.6119 129.547 26.5061 129.117 26.5061C128.664 26.5061 128.304 26.6051 128.038 26.803C127.775 27.0009 127.597 27.2346 127.505 27.5041L125.775 27.2585C125.912 26.7808 126.137 26.3816 126.451 26.0608C126.765 25.7367 127.149 25.4945 127.603 25.3341C128.056 25.1703 128.558 25.0884 129.107 25.0884C129.486 25.0884 129.863 25.1328 130.238 25.2215C130.614 25.3102 130.957 25.4569 131.267 25.6616C131.577 25.8629 131.827 26.1376 132.014 26.4856C132.205 26.8337 132.301 27.2687 132.301 27.7907V33.052H130.52V31.9721H130.458C130.346 32.1905 130.187 32.3952 129.982 32.5863C129.781 32.774 129.527 32.9258 129.22 33.0418C128.916 33.1544 128.56 33.2107 128.15 33.2107ZM128.631 31.8493C129.003 31.8493 129.326 31.7759 129.599 31.6292C129.871 31.4791 130.081 31.2812 130.228 31.0355C130.378 30.7899 130.453 30.522 130.453 30.232V29.3057C130.395 29.3534 130.296 29.3978 130.156 29.4387C130.02 29.4797 129.866 29.5155 129.696 29.5462C129.525 29.5769 129.356 29.6042 129.189 29.6281C129.022 29.652 128.877 29.6724 128.754 29.6895C128.478 29.727 128.23 29.7885 128.012 29.8738C127.794 29.9591 127.621 30.0785 127.495 30.232C127.369 30.3821 127.306 30.5766 127.306 30.8155C127.306 31.1567 127.43 31.4143 127.679 31.5883C127.928 31.7623 128.246 31.8493 128.631 31.8493Z"
      fill="currentColor"
    />
  </svg>
));

Logo.displayName = "Logo";
export default Logo;
